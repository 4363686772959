import React from "react"

import downloadbuttonsStyles from "./downloadbuttons.module.scss"
import { DownloadAppstore, DownloadGoogleplay } from "./downloadButtons"



const DownloadButtons = () =>(
    <div className={downloadbuttonsStyles.wrap}>
        <p className={downloadbuttonsStyles.copy}>Consíguela ya</p>
        <DownloadAppstore btnSize="normal"/>
        <DownloadGoogleplay btnSize="normal"/>
    </div>
)

export default DownloadButtons