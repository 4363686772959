import React from "react"
import { Link } from "gatsby"

import downloadbuttonsStyles from "./downloadbuttons.module.scss"
import BadgeAppstore from "../../static/app-store-badge.svg"
import BadgeGoogleplay from "../../static/google-play-badge.svg"


const DownloadButton = props => (
    <Link to="/descargar/descargar-app/" className={downloadbuttonsStyles.button + ' ' + downloadbuttonsStyles[props.btnSize]}>
            <img src={props.src} id={props.id} alt={props.alt}/>
    </Link>
)

export const DownloadAppstore = props => (
    <DownloadButton
        src={BadgeAppstore}
        id="app-store"
        alt="Descargar Parquick en App Store"
        btnSize={props.btnSize}
    />
)

export const DownloadGoogleplay = props => (
    <DownloadButton
        src={BadgeGoogleplay}
        id="google-play"
        alt="Descargar Parquick en Google Play"
        btnSize={props.btnSize}
    />
)