import React from "react"
import {
    isAndroid,
    isIOS
  } from "react-device-detect";
import { DownloadAppstore, DownloadGoogleplay } from "./downloadButtons";
import downloadbuttonsStyles from "./downloadbuttons.module.scss"

export default class DownloadDinamic extends React.Component {

    renderContent = () => {
        if (isIOS) {
            return <DownloadAppstore btnSize="small"/>
        }
        if (isAndroid) {
            return <DownloadGoogleplay btnSize="small"/>
        }
        return <div className={downloadbuttonsStyles.bannerwrap}> <DownloadAppstore btnSize="small"/><DownloadGoogleplay btnSize="small"/> </div>
    }

    render() {
        return this.renderContent();
    }
}