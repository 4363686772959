import React from "react"

import downloadnavStyles from "./downloadnav.module.scss"
import DownloadDinamic from "../downloadButtons/downloadDinamic"


const DownloadNav= () => (
    <div className={downloadnavStyles.wrap}>
        <p className={downloadnavStyles.copy}>Consíguela en</p>
        <DownloadDinamic/>
    </div>
)

export default DownloadNav