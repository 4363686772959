import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll';
import Modal from "../components/modal/modal";
import indexStyles from "../styles/index.module.scss"
import calculatorStyles from "../components/calculator/calculator.module.scss"

import { Container, Grid } from "../components/grid/grid"
import { DivisorLarge } from "../components/divisor/divisor"
import Iconrow from "../components/iconrow/iconrow"
import { Warning } from "../components/textinfo/textinfo"
import Subfooter from "../components/footer/subfooter"

import Car from "../static/car.svg"
import BadgeAppstore from "../static/app-store-badge.svg"
import BadgeGoogleplay from "../static/google-play-badge.svg"
import ImageRadar from "../components/images/imageRadar"
import ImageLight from "../static/light.svg"
import ImageSaving from "../static/ahorrar-dinero-aparcar-barcelona.svg"
import ImageSavingBg from "../static/ahorrar-dinero-parkings-barcelona.svg"
import ImageMoneda from "../static/moneda.svg"
import ImageRelax from "../static/relax.svg"
import ImageAppPoster from "../images/app-poster.jpg"
import VideoAppMp4 from "../static/app-animation.mp4"
import VideoAppWebm from "../static/app-animation.webm"
import DownloadButtons from "../components/downloadButtons/downloadNow";
import DownloadNav from "../components/banner/downloadnav";

export default class IndexPage extends React.Component {

  // Creamos las variables. Asignamos el valor por defecto (coche gasolina etiqueta C)
  constructor() {
    super();
    this.state = {
        timeMinutes: 18, // Tiempo medio búsqueda en minutos
        timeHours: 0.3, // Tiempo medio búsqueda en horas
        distance: 4.2, // Distancia recorrida por búsqueda

        searchsMonth: 15, // Búsquedas al mes
        searchsYear: 180, // Búsquedas al año

        consume: "6.8", // Consumo L/100km
        consumeKm: 0.068, // Consumo L/1km

        fuelType: 0, // Tipo de combustible [1 Gasolina] [2 Diésel]
        fuelPrice: 1.287, // Precio combustible 1L
        co2: 0.15307, // kgCO2/km
        no2: 0.27, // gNO2/km
        wear: 0.048, // Precio desgaste €/km

        parquickco2: 0.0714, // kgCO2/km
        parquickno2: 0.1259, // gNO2/km
    }
}

handleTimeChange(e) {
  const newTime = e.target.value;
  this.setState({
      timeMinutes: newTime,
      timeHours: newTime / 60,
      distance: newTime / 60 * 14 //13 es la velocidad media km/h
  });
}

handleSearchsChange(e) {
  const newSearchs = e.target.value;
  this.setState({
      searchsMonth: newSearchs,
      searchsYear: newSearchs * 12
  });
}

handleConsumeChange(e) {
  const newConsume = e.target.value;
  this.setState({
      consume: newConsume,
      consumeKm: newConsume / 100 //calculamos el consumo por km
  });
}

handleFuelChange(e) {
  const newFuel = e.target.value;

  if(newFuel == 1){
    this.setState({
      fuelType: newFuel,
      fuelPrice: 1.364, // Precio Litro Gasolina 95
      co2: 0.18138, // kgCO2/km en coche gasolina etiqueta C
      no2: 0, // gNO2/km en coche gasolina etiqueta C
      parquickco2: 0.0846, // kgCO2/km
      parquickno2: 0, // gNO2/km
    });
  } else if(newFuel == 2){
    this.setState({
      fuelType: newFuel,
      fuelPrice: 1.287, // Precio litro Gasóleo
      co2: 0.15307, // kgCO2/km en coche diésel etiqueta C
      no2: 0.27, // gNO2/km en coche diésel etiqueta C
      parquickco2: 0.0714, // kgCO2/km
      parquickno2: 0.1259 // gNO2/km
    });
  }
}

handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
}


  render() {

  return (
    <div>
            <Modal id="calcule" idclose="#calculator">
              <form>
                    <div className={indexStyles.inputWrap + " l-start-1 l-end-6 m-start-1 m-end-4"}>
                        <label>Minutos que tardas en aparcar:</label>
                        <div class="rangevalue">{this.state.timeMinutes} minutos</div>
                        <input type="range" min="5" max="45" value={this.state.timeMinutes} onChange={this.handleTimeChange.bind(this)}/>
                    </div>

                    <div className={indexStyles.inputWrap + " l-start-7 l-end-12 m-start-4 m-end-7"}>
                        <label>Veces que aparcas cada mes:</label>
                        <div class="rangevalue">{this.state.searchsMonth} veces</div>
                        <input type="range" min="1" max="60" value={this.state.searchsMonth} onChange={this.handleSearchsChange.bind(this)}/>
                    </div>

                    <div className={indexStyles.inputWrap + " l-start-1 l-end-6 m-start-1 m-end-4"}>
                        <label class="label-small">Tipo de combustible:</label>
                        <select value={this.state.fuelType} onChange={this.handleFuelChange.bind(this)} class="selectLarge">
                            <option value="2">Diésel</option>
                            <option value="1">Gasolina</option>
                        </select>
                    </div>

                    <div className={indexStyles.inputWrap + " l-start-7 l-end-12 m-start-4 m-end-7"}>
                        <label>Consumo medio en ciudad:</label>
                        <select value={this.state.consume} onChange={this.handleConsumeChange.bind(this)} class="selectLarge">
                            <option value="3">3 L/100km</option>
                            <option value="3.2">3,2 L/100km</option>
                            <option value="3.4">3,4 L/100km</option>
                            <option value="3.6">3,6 L/100km</option>
                            <option value="3.8">3,8 L/100km</option>
                            <option value="4">4 L/100km</option>
                            <option value="4.2">4,2 L/100km</option>
                            <option value="4.4">4,4 L/100km</option>
                            <option value="4.6">4,6 L/100km</option>
                            <option value="4.8">4,8 L/100km</option>
                            <option value="5">5 L/100km</option>
                            <option value="5.2">5,2 L/100km</option>
                            <option value="5.4">5,4 L/100km</option>
                            <option value="5.6">5,6 L/100km</option>
                            <option value="5.8">5,8 L/100km</option>
                            <option value="6">6 L/100km</option>
                            <option value="6.2">6,2 L/100km</option>
                            <option value="6.4">6,4 L/100km</option>
                            <option value="6.6">6,6 L/100km</option>
                            <option value="6.8">6,8 L/100km</option>
                            <option value="7">7 L/100km</option>
                            <option value="7.2">7,2 L/100km</option>
                            <option value="7.4">7,4 L/100km</option>
                            <option value="7.6">7,6 L/100km</option>
                            <option value="7.8">7,8 L/100km</option>
                            <option value="8">8 L/100km</option>
                            <option value="8.2">8,2 L/100km</option>
                            <option value="8.4">8,4 L/100km</option>
                            <option value="8.6">8,6 L/100km</option>
                            <option value="8.8">8,8 L/100km</option>
                            <option value="9">9 L/100km</option>
                            <option value="9.2">9,2 L/100km</option>
                            <option value="9.4">9,4 L/100km</option>
                            <option value="9.6">9,6 L/100km</option>
                            <option value="9.8">9,8 L/100km</option>
                            <option value="10">10 L/100km</option>
                            <option value="10.2">10,2 L/100km</option>
                            <option value="10.4">10,4 L/100km</option>
                            <option value="10.6">10,6 L/100km</option>
                            <option value="10.8">10,8 L/100km</option>
                            <option value="11">11 L/100km</option>
                            <option value="11.2">11,2 L/100km</option>
                            <option value="11.4">11,4 L/100km</option>
                            <option value="11.6">11,6 L/100km</option>
                            <option value="11.8">11,8 L/100km</option>
                            <option value="12">12 L/100km</option>
                            <option value="12.2">12,2 L/100km</option>
                            <option value="12.4">12,4 L/100km</option>
                            <option value="12.6">12,6 L/100km</option>
                            <option value="12.8">12,8 L/100km</option>
                            <option value="13">13 L/100km</option>
                            <option value="13.2">13,2 L/100km</option>
                            <option value="13.4">13,4 L/100km</option>
                            <option value="13.6">13,6 L/100km</option>
                            <option value="13.8">13,8 L/100km</option>
                            <option value="14">14 L/100km</option>
                            <option value="14.2">14,2 L/100km</option>
                            <option value="14.4">14,4 L/100km</option>
                            <option value="14.6">14,6 L/100km</option>
                            <option value="14.8">14,8 L/100km</option>
                            <option value="15">15 L/100km</option>
                            <option value="15.2">15,2 L/100km</option>
                            <option value="15.4">15,4 L/100km</option>
                            <option value="15.6">15,6 L/100km</option>
                            <option value="15.8">15,8 L/100km</option>
                        </select>
                    </div>

                    <a href="#calculator" title="Close" className={indexStyles.modalbtn}>
                      Calcular
                    </a>
        </form>
      </Modal>


      <Layout trialbtnClass="hidden">
      <SEO 
        title="Parquick - Encuentra parking en Barcelona cerca de ti, al instante" 
        description="La app que busca el aparcamiento más cercano en Barcelona y te lleva hasta él. Al momento, ahorrando tiempo, dinero, y sin estrés. ¡Pruébala gratis!"
      />
      <DownloadNav/>
      <header>
        <div className={indexStyles.problemWrap}>
          
          <div className={indexStyles.problemContent}>
            <div className={indexStyles.carsWrap}>
              <div className={indexStyles.carWrap}>
                <img className={indexStyles.car} src={Car} alt="Buscando parking en Barcelona"/>
              </div>
              <div className={indexStyles.carWrap}>
                <img className={indexStyles.car} src={Car} alt="Encontrar parking cerca de mi"/>
              </div>
              <div className={indexStyles.carWrap}>
                <img className={indexStyles.car} src={Car} alt="Encontrando aparcamiento en Barcelona"/>
              </div>
            </div>

            <span className={indexStyles.title}><span className={indexStyles.break}>Cada año</span> estás perdiendo</span>
            <span className={indexStyles.day}>3 días</span>
            <span className={indexStyles.title}>completos buscando aparcamiento</span>
          </div>

          <button className={indexStyles.keepreading} onClick={() => scrollTo('#scroll')}>
            ¡Recupéralos ahora!
            <i class="icon-down_24"></i>
          </button>
          
        </div>

        

        <Container id="scroll" class={indexStyles.solveWrap}>
          <h1 className={indexStyles.title + ' ' + indexStyles.solveTitle}>
            Parquick busca y te lleva al parking más cercano a tu destino en Barcelona,
            <div className={indexStyles.switchwords}>
              <div><span className={indexStyles.word}>al momento</span></div>
              <div><span className={indexStyles.word}>ahorrando tiempo</span></div>
              <div><span className={indexStyles.word}>contaminando menos</span></div>
              <div><span className={indexStyles.word}>sin estrés</span></div>
              <div><span className={indexStyles.word}>ahorrando dinero</span></div>
            </div>
          </h1>

          <div className={indexStyles.cta}>
            <p>Descárgala gratis</p>
            <Link to="/descargar/descargar-app/" id="Header-Descargar" className={indexStyles.storeButton}>
              <img src={BadgeAppstore} id="app-store" alt="Descargar Parquick en App Store"/>
            </Link>
            <Link to="/descargar/descargar-app/" id="Header-Descargar" className={indexStyles.storeButton}>
              <img src={BadgeGoogleplay} id="google-play" alt="Descargar Parquick en Google Play"/>
            </Link>
          </div>
        </Container>
        <div className={indexStyles.cityWrap}>
          <div className={indexStyles.cityBack}></div>
          <div className={indexStyles.cityCar}></div>
          <div className={indexStyles.cityFront}></div>
        </div>
      </header>

      <Container>

        <section className={indexStyles.nowRow}>
          <div className={indexStyles.nowGrid}>
            <div className={indexStyles.nowIntro}>
              <p className={indexStyles.sectiontitle}>¡Ya!</p>
              <h2>¿A dónde vas? <br />Parquick te mostrará donde aparcar</h2>
            </div>
            
            <div className={indexStyles.nowMobile}>
              <div className={indexStyles.sticky}>
                <div className={indexStyles.nowMobileImg}>
                  <video autoPlay loop muted playsInline width="292" height="682" poster={ImageAppPoster}>
                    <source src={VideoAppWebm} type="video/webm"/>
                    <source src={VideoAppMp4} type="video/mp4"/>
                  </video>
                </div>
              </div>
            </div>
            
            <div className={indexStyles.nowFeatures}>

            <div className={indexStyles.feature}>
                <div className={indexStyles.featureIcon + ' ' + indexStyles.bold}>
                  <i class="icon-electric_24"></i>
                </div>
                <div className={indexStyles.featureContent}>
                  <h3>¡Quick quick quick!</h3>
                  <p>Rápido y sencillo. Localiza al instante los parkings con plazas libres más cercanos allí donde vayas.</p>
                </div>
              </div>
              <DivisorLarge />
              <div className={indexStyles.feature}>
                <div className={indexStyles.featureIcon}>
                  <ImageRadar className={indexStyles.radar} alt="buscar parking de forma inteligente"/>
                </div>
                <div className={indexStyles.featureContent}>
                  <h3>Búsqueda inteligente</h3>
                  <p>Parquick te indica los 3 parkings cercanos que más se ajustan a tus necesidades.</p>
                  <p>Compara tarifas <i class="icon-cash_24"></i>, tiempo conduciendo <i class="icon-car_24"></i> y andando <i class="icon-walk_24"></i> desde el parking a tu destino. Tú escoges, Parquick te lleva.</p>
                </div>
              </div>
              <DivisorLarge />
              <div className={indexStyles.feature}>
                <div className={indexStyles.featureIcon}>
                  <span className={indexStyles.lightCircle}></span>
                  <img src={ImageLight} className={indexStyles.light} alt="encontrar aparcamiento con plazas libres"/>
                </div>
                <div className={indexStyles.featureContent}>
                  <h3>Plazas libres en tiempo real</h3>
                  <p>Conectado con los parkings de Barcelona para que no tengas sorpresas con su ocupación.</p>
                </div>
              </div>
              <DivisorLarge />
              <div className={indexStyles.feature}>
                <div className={indexStyles.featureIcon}>
                  <i class="icon-route_24"></i>
                </div>
                <div className={indexStyles.featureContent}>
                  <h3>Déjate llevar</h3>
                  <p>Te guía directo hasta el parking elegido por la ruta más rápida. Sin rodeos. Sin perder tiempo en realizar una reserva.</p>
                  <Warning class="margintop1"/>
                </div>
              </div>
              <DownloadButtons/>
            </div>
          </div>
        </section>

        <section className={indexStyles.savingRow}>
          <Grid class="margintop5">
            <div className={indexStyles.savingImage + " l-start-1 l-end-7 m-start-1 m-end-4"}>
              <img src={ImageSaving} alt="app para encontrar aparcamiento ahorrando"/>
              <img src={ImageMoneda} className={indexStyles.savingImageCoin} alt="encontrar parking y ahorrar"/>
              <img src={ImageSavingBg} className={indexStyles.savingImageBg} alt="buscar aparcamiento en barcelona ahorrando"/>
            </div>

            <div className={indexStyles.savingcontent +  " l-start-7 l-end-13 m-start-4 m-end-7"}>
              <p className={indexStyles.sectiontitle}>Ahorra</p>
              <h2>Gana tiempo, reduce la contaminación y ahorra dinero</h2>
              <p>No des vueltas y vueltas buscando una plaza libre. Parquick te lleva directo hasta el aparcamiento.</p>
              <p>Menos tiempo al volante es más tiempo para ti, contaminar hasta 10 veces menos, y evitar el malgasto del combustible y desgastes en tu vehículo.</p>
            </div>
          </Grid>

          <Grid class="margintop4">
            <div id="calculator" class="l-start-1 l-end-7 m-start-1 m-end-4">
                    <h2>¿Cuánto puedes ahorrar?</h2>
            </div>
          </Grid>

          <Grid class="aligncenter">

              <div class="l-start-1 l-end-6 m-start-1 m-end-4">
                  <table className={calculatorStyles.table} cellSpacing="0">
                      <tr>
                          <th></th>
                          <th className={calculatorStyles.withoutparquick}>Sin Parquick</th>
                          <th className={calculatorStyles.withparquick}>Con Parquick</th>
                      </tr>

                      <tr>
                          <td className={calculatorStyles.labelcell}>
                              Lugar de aparcamiento
                          </td>
                          <td className={calculatorStyles.textcell + ' ' + calculatorStyles.withoutparquick}>
                            <p>Parking</p>
                            <p>Vía pública</p>
                          </td>
                          <td className={calculatorStyles.textcell + ' ' + calculatorStyles.withparquick}>
                              Parking
                          </td>
                      </tr>

                      <tr>
                          <td className={calculatorStyles.labelcell}>
                              Tiempo para encontrar aparcamiento.
                          </td>
                          <td className={calculatorStyles.datacell + ' ' + calculatorStyles.withoutparquick}>
                              {this.state.timeMinutes} min
                          </td>
                          <td className={calculatorStyles.datacell + ' ' + calculatorStyles.withparquick}>
                              2 min
                          </td>
                      </tr>

                      <tr>
                          <td className={calculatorStyles.labelcell}>
                              Gases CO2 emitidos.
                          </td>
                          <td className={calculatorStyles.datacell + ' ' + calculatorStyles.withoutparquick}>
                              {(this.state.co2 * this.state.distance * 1000).toFixed(1)}g
                          </td>
                          <td className={calculatorStyles.datacell + ' ' + calculatorStyles.withparquick}>
                              {(this.state.parquickco2 * 1000).toFixed(1)}g
                          </td>
                      </tr>

                      <tr>
                          <td className={calculatorStyles.labelcell}>
                              Gases NOx emitidos.
                          </td>
                          <td className={calculatorStyles.datacell + ' ' + calculatorStyles.withoutparquick}>
                              {(this.state.no2 * this.state.distance).toFixed(2)}g
                          </td>
                          <td className={calculatorStyles.datacell + ' ' + calculatorStyles.withparquick}>
                              {(this.state.parquickno2).toFixed(2)}g
                          </td>
                      </tr>

                      <tr>
                          <td className={calculatorStyles.labelcell}>
                              Gastos de combustible y desgastes.
                          </td>
                          <td className={calculatorStyles.datacell + ' ' + calculatorStyles.withoutparquick}>
                              {((this.state.wear + this.state.fuelPrice * this.state.consumeKm) * this.state.distance).toFixed(2)}€
                          </td>
                          <td className={calculatorStyles.datacell + ' ' + calculatorStyles.withparquick}>
                              0,05€
                          </td>
                      </tr>

                  </table>
                      
              </div>
              <div class="l-start-7 l-end-13 m-start-4 m-end-7">
                <div className={calculatorStyles.cal}>
                  <h3 className={calculatorStyles.title}>Con Parquick ahorras cada año:</h3>
                  <div className={calculatorStyles.calitemWrap}>
                      
                      <div className={calculatorStyles.calitem}>
                          <i class="icon-time_24"></i>
                          <p className={calculatorStyles.calnumber}>
                              {((this.state.timeMinutes - 2) / 60 * this.state.searchsYear).toFixed(1)} horas
                          </p>
                      </div>

                      <div className={calculatorStyles.calitem}>
                          <i class="icon-pollutionCO2_24"></i>
                          <p className={calculatorStyles.calnumber}>
                              {((this.state.co2 * this.state.distance - this.state.parquickco2) * this.state.searchsYear).toFixed(1)} kg CO2
                          </p>
                      </div>

                      <div className={calculatorStyles.calitem}>
                          <i class="icon-pollutionNO2_24"></i>
                          <p className={calculatorStyles.calnumber}>
                              {((this.state.no2 * this.state.distance - this.state.parquickno2)* this.state.searchsYear).toFixed(1)} g NOx
                          </p>
                      </div>
                      
                      <div className={calculatorStyles.calitem}>
                          <i class="icon-saving_24"></i>
                          <div>
                          <p className={calculatorStyles.calnumber}>
                              {(((this.state.wear + this.state.fuelPrice * this.state.consumeKm) * this.state.distance - 0.05) * this.state.searchsYear).toFixed(2)}€
                          </p>
                          <p className={calculatorStyles.calnote}>En combustible y desgastes</p>
                          </div>
                      </div>
                      <a href="#calcule" className={calculatorStyles.linkmodal}>Calcula tu ahorro</a>
                  </div>

                  <div className={calculatorStyles.calbg}>
                      <i className={"icon-cash_24 " + calculatorStyles.calbg1}></i>
                      <i className={"icon-saving_24 " + calculatorStyles.calbg2}></i>
                      <i className={"icon-saving_24 " + calculatorStyles.calbg3}></i>
                      <i className={"icon-cash_24 " + calculatorStyles.calbg4}></i>
                  </div>
                </div>

                <div className={calculatorStyles.calfooter}>
                  <p>Cifras aproximadas.</p>
                  <p>Gases contaminantes obtenidos a partir de una media de vehículos con etiqueta C.</p>
                </div>
              </div>

              <div class="l-start-1 l-end-7 m-start-1 m-end-4">
                <DownloadButtons/>
              </div>

          </Grid>
        </section>

        <section>
          <Grid class={indexStyles.relax}>
            <div class="l-start-1 l-end-13 m-start-1 m-end-7">
              <p className={indexStyles.sectiontitle}>Relájate</p>
            </div>
            <div class="l-start-4 l-end-10 m-start-2 m-end-6">
              <div className={indexStyles.relaxImage}>
                <img src={ImageRelax} className={indexStyles.relaxImageCharacter} alt="No te estreses al buscar parking en Barcelona"/>
                <svg className={indexStyles.relaxImageShadow} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  viewBox="0 0 540 350">
                  <ellipse cx="270" cy="342.82858" rx="79.34129" ry="5.6306"/>
                </svg>
              </div>
              <h2>Aparcar en Barcelona sin estrés sí es posible</h2>
              <p>Di adiós a la duda de si encontrarás o no un lugar donde aparcar, o de llegar siempre tarde a los sitios por culpa de buscar aparcamiento en Barcelona.</p>
              <p class="body-note">(Aunque no podemos evitar que te sigas estresando al conducir por la ciudad...)</p>
            </div>

            <Iconrow class="margintop3 l-start-3 l-end-11 m-start-1 m-end-7"
              icon1="icon-car_24"
              text1="Aparcarás siempre"
              icon2="icon-electric_24"
              text2="Al momento"
              icon3="icon-location_24"
              text3="Cerca de tu destino"
            />
          </Grid>
        </section>
      </Container>
      
      <Subfooter />
    </Layout>
  </div>
  );
  }
}