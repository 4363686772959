import React from "react"

import iconrowStyles from "./iconrow.module.scss"

const Iconcolumn = props => (
    <div className={iconrowStyles.iconcolumn}>
        <i class={props.icon}></i>
        <p>{props.children}</p>
    </div>
)

const Iconrow = props => (
    <div className={iconrowStyles.iconrow + ' ' + [props.class]}>
        <Iconcolumn icon={props.icon1}>{props.text1}</Iconcolumn>
        <Iconcolumn icon={props.icon2}>{props.text2}</Iconcolumn>
        <Iconcolumn icon={props.icon3}>{props.text3}</Iconcolumn>
    </div>
)


export default Iconrow